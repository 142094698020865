import { strict } from 'assert';
import { HTMLInputAutoCompleteAttribute } from 'react';
import {
  CompanySizesOptions,
  ContactFormFields,
  PartnerTypesOptions,
} from '@transcend-io/contact-form-schema';
import { AnimatePresence, motion } from 'framer-motion';

import { ChiliPiperScript } from '@/lib/chili-piper/script';
import { triggerSchedulingModal } from '@/lib/chili-piper/trigger';
import { checkFormServerError } from '@/lib/contact-form-client/helpers';
import { isPersonalEmail } from '@/lib/contact-form-client/personal-email';
import type {
  SanityForm,
  SanityFormField,
  SanityFormFieldEmail,
} from '@/lib/sanity/queries/form';
import type { SanityFormConfirmation } from '@/lib/sanity/queries/formConfirmation';
import { cn } from '@/lib/utils';
import { BasicText } from '@/components/BasicText';
import { Button } from '@/components/Button';
import { CountryRegionSelector } from '@/components/CountryRegionSelector';
import { Input } from '@/components/Input';
import { Listbox } from '@/components/Listbox';
import { Textarea } from '@/components/Textarea';

import { Form } from './Form';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from './FormControls';
import { Headline } from './Headline';

function getAutoCompleteAttribute(
  fieldName: keyof typeof ContactFormFields.shape,
): HTMLInputAutoCompleteAttribute {
  switch (fieldName) {
    case 'company':
      return 'organization';
    case 'title':
      return 'organization-title';
    case 'firstName':
      return 'given-name';
    case 'lastName':
      return 'family-name';

    default:
      return '';
  }
}

function getFormFieldComponent(fieldData: SanityFormField, fieldProps: any) {
  switch (fieldData._type) {
    case 'text':
      return (
        <Input
          type={fieldData._type}
          required={fieldData.isRequired}
          placeholder={fieldData.label}
          autoComplete={getAutoCompleteAttribute(fieldProps.name)}
          {...fieldProps}
        />
      );
    case 'email':
      return (
        <Input
          type={fieldData._type}
          autoComplete="work email"
          required={fieldData.isRequired}
          placeholder={fieldData.label}
          {...fieldProps}
        />
      );
    case 'tel':
      return (
        <Input
          type={fieldData._type}
          autoComplete="work tel"
          required={fieldData.isRequired}
          placeholder={fieldData.label}
          {...fieldProps}
        />
      );
    case 'textarea':
      return (
        <Textarea
          required={fieldData.isRequired}
          placeholder={fieldData.label}
          autoComplete={getAutoCompleteAttribute(fieldProps.name)}
          {...fieldProps}
        />
      );
    case 'select':
      let options;
      if (fieldData.name === 'partnerType') {
        options = PartnerTypesOptions;
      } else if (fieldData.name === 'companySize') {
        options = CompanySizesOptions;
      }
      return (
        <Listbox
          {...fieldProps}
          options={options?.map((option) => ({
            label: option,
            value: option,
          }))}
          placeholder={fieldData.label}
        />
      );
    default:
      return null;
  }
}

type Props = {
  form: SanityForm;
  confirmation: SanityFormConfirmation;
  onSubmitSuccess?: () => void;
  onSubmitError?: () => void;
};

export function FormCustom(props: Props) {
  strict(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY);

  // If the email field requires a business address, we need to add a validation rule to the form schema
  const emailField = props.form.fields.find(
    (field) => 'name' in field && field.name === 'email',
  ) as SanityFormFieldEmail | undefined;
  const formFieldsSchema = emailField?.requireBusinessEmail
    ? ContactFormFields.extend({
        // Validate that the email address is not a personal email
        email: ContactFormFields.shape.email.refine(
          (value) => !isPersonalEmail(value),
          {
            message: 'Please enter a work email address.',
          },
        ),
      })
    : ContactFormFields;

  /**
   * On form success, trigger the ChiliPiper scheduling modal and then call the supplied callback
   */
  const onSubmitSuccessWrapped = (
    onSubmitSuccess: Props['onSubmitSuccess'],
    enableScheduling: Props['form']['enableScheduling'],
  ) => {
    if (enableScheduling) {
      // Trigger the ChiliPiper scheduling modal
      triggerSchedulingModal();
    }
    // Call the original onSubmitSuccess callback
    onSubmitSuccess?.();
  };

  return (
    <div className="relative">
      <ChiliPiperScript />
      <Form
        pardotCampaignId={props.form.pardotCampaignId}
        pardotListIds={props.form.pardotListIds}
        formFieldsSchema={formFieldsSchema}
        trackingEventName={props.form.trackingEventName}
        onSubmitSuccess={() =>
          onSubmitSuccessWrapped(
            props.onSubmitSuccess,
            props.form.enableScheduling,
          )
        }
        onSubmitError={props.onSubmitError}
        confirmationComponent={
          <div>
            <Headline value={props.confirmation.headline} variant={5} />
            <p className="text-body mt-15">{props.confirmation.bodyCopy}</p>
            {props.confirmation.cta?.href ? (
              <Button
                className="mt-20"
                href={props.confirmation.cta.href}
                target={props.confirmation.cta.target}
                variant="secondary"
              >
                {props.confirmation.cta.title}
              </Button>
            ) : null}
          </div>
        }
      >
        {({ control, formState }) => {
          const serverError = checkFormServerError(formState);

          return (
            <div className="grid grid-cols-2 gap-20">
              {props.form.fields.map((fieldData) => {
                if (fieldData._type === 'countryRegion') {
                  return (
                    <CountryRegionSelector
                      key={fieldData._key}
                      label={fieldData.label}
                    />
                  );
                } else {
                  return (
                    <FormField
                      key={fieldData._key}
                      name={fieldData.name}
                      control={control}
                      defaultValue={''}
                      render={({ field: fieldProps }) => {
                        return (
                          <FormItem
                            className={cn('col-span-full', {
                              'l:col-span-1':
                                'isHalfWidthOnDesktop' in fieldData &&
                                fieldData.isHalfWidthOnDesktop,
                            })}
                          >
                            <FormLabel className="sr-only">
                              {fieldData.label}
                            </FormLabel>
                            <FormControl>
                              {getFormFieldComponent(fieldData, fieldProps)}
                            </FormControl>
                            <FormDescription />
                            <FormMessage className="text-legal mt-4 text-flame-06" />
                          </FormItem>
                        );
                      }}
                    />
                  );
                }
              })}
              {serverError && (
                <p className="col-span-full text-legal text-flame-06">
                  {serverError.message}
                </p>
              )}
              <Button className="col-span-full" variant="form" type="submit">
                <AnimatePresence mode="popLayout">
                  {formState.isSubmitting ? (
                    <motion.div
                      key="spinner"
                      className="mx-auto size-14 animate-spin rounded-full border-2 border-r-transparent"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <p className="sr-only">Submitting form…</p>
                    </motion.div>
                  ) : (
                    <motion.div
                      key="label"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      {props.form.submitButtonLabel}
                    </motion.div>
                  )}
                </AnimatePresence>
              </Button>
              {props.form.legalText ? (
                <BasicText
                  className="text-legal col-span-full mt-10 font-light text-grayscale-05"
                  value={props.form.legalText}
                />
              ) : null}
            </div>
          );
        }}
      </Form>
    </div>
  );
}
